header.main {
	border-bottom: 1px solid $gray-300;
	.logo-link {
		text-decoration: none;
		color: $black;
		h5 {
			font-size: 15px;
			margin: 0;
		}
	}
	.user-info {
		p {
			font-size: 14px;
			margin: 0;
		}
		a {
			text-decoration: none;
			font-size: 14px;
		}
	}
}