@import "config";
@import "~bootstrap/scss/bootstrap";
@import "modules/header";
@import "app";

.btn.btn-secondary{
	color: #fff;
}

.main-body .btn {
	padding: 6px 15px 10px 15px;
}

.main-body .form-control {
	padding: 6px 10px 8px 10px;
}

.main-body .alert {
  padding: 8px 10px 10px 10px;
}

.main-body {
  .form-floating > .form-control, .form-floating > .form-select {
    height: calc(3rem + 2px);
  }
  .form-floating > label {
    top: -5px;
    left: -1px;
  }
  .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
    transform: scale(0.75) translateY(-0.5rem) translateX(0.15rem);
  }
  ul {
    li.vote-li {
      background: #fff;
      border: 1px solid #dbdbdb;
      border-radius: 12px;
      padding: 20px;
      &.vetoed {
        background: #ffeaea;
        position: relative;
        &::before {
          content: "VETO'ed";
          background: #d54f4f;
          color: #fff;
          display: block;
          position: absolute;
          right: -15px;
          top: 0;
          transform: rotate(12deg);
          font-size: 16px;
          font-weight: 500;
          border-radius: 8px;
          padding: 4px 10px 5px 10px;
        }
      }
      h6 {
        font-size: 1.2em;
        margin: 0 0 5px 0;
      }
      p {
        font-size: 0.9em;
        margin: 0 0 5px 0;
      }
      button.veto {
        color: #d54f4f;
        text-decoration: none;
        padding: 0;
        border: 0;
        text-transform: uppercase;
        margin-left: 10px;
        font-size: 13px;
        line-height: 24px;
        border-radius: 0;
        display: block;
      }
      p.status {
        margin: 0;
        display: flex;
        align-items: center;
        height: 28px;
        font-size: 13px;
        line-height: 24px;
        &.green > span {
          color: #30cb30;
          display: inline-block;
          margin-right: 4px;
        }
        &.red > span {
          color: #d54f4f;
          display: inline-block;
          margin-right: 4px;
        }
        &.green span.vt,
        &.red span.vt {
          color: gray;
          display: flex;
          align-items: center;
          margin-left: 10px;
        }
        span.al {
          color: gray;
        }
      }
    }
  }

  .btn.google-btn {
    background-color: #4285F4;
    border-color: #4285F4;
    &:hover {
      background-color: #376cc5;
    }
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(#376cc5, 0.5);
    }
  }
  .btn.facebook-btn {
    background-color: #4267B2;
    border-color: #4267B2;
    &:hover {
      background-color: #34528f;
    }
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(#4267B2, 0.5);
    }
  }
  .group-results {
    p {
      margin: 10px 0 0 0;
    }
  }
  .restaurant-choice {
    span.r-index {
      width: 50px;
      height: 50px;
      display: block;
      flex: 0 0 50px;
      color: #fff;
      background: $secondary;
      border-radius: 50%;
      text-align: center;
      font-weight: 500;
      font-size: 1.9em;
    }
    .r-text {
      flex: 1;
      padding-left: 20px;
      h6 {
        margin-bottom: 5px;
      }
      p.hours {
        font-size: 14px;
        margin: 0 0 2px 0;
      }
      address {
        font-size: 14px;
        margin: 0;
      }
      .alert {
        margin: 10px 0 0 0;
      }
    }
  }
}