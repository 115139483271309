.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
}

.main-body {
	max-width: 450px;
	margin: 0 auto;
}

.btn.full-width {
	display: block;
	width: 100%;
}

.btn.btn-primary.faded {
	opacity: 0.5;
}

body {
	background-color: #fffbf6;
}