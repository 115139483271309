$primary: #cd2c5a;
$secondary: #d3a06c;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "dark": #000000,
  "gray": #747474,
  "danger": #fdeaea,
  "warning": #d3a06c
);

$font-family-sans-serif: "Zen Kaku Gothic Antique";
$headings-font-weight: 500;